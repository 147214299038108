export type T_StartPageBankProperties = {
    url: string;
    width: string | Array<string>;
    height: string | Array<string>;
};

export enum E_Fonts {
    FONT_FAMILY = "Open Sans",
    BASIC_FONT_SIZE = "16px",
    TABLET_FONT_SIZE = "14px",
    SMALLER_FONT_SIZE = "14px",
    BASIC_FONT_COLOR = "#124890",
    BASIC_LINE_HEIGHT = "1.3",
    BASIC_FONT_TITLE_SIZE = "24px",
}

export enum E_Colors {
    PRIMARY = "#124890",
    BORDER = "#92c9ea",
    NEWS_DATE = "#4fa93c",
    LINK = "#66aadf",
    ERROR = "#e12529",
    WARNING = "#F0A80E",
}
