import React, { FunctionComponent, Dispatch, SetStateAction } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { IconContainer } from "@opr-finance/component-navigation";
import { Scroll } from "@opr-finance/component-scroll";
import { MobileNavStyles } from "@opr-finance/theme-flex-online";
import { StyledGrid } from "@opr-finance/component-grid";
import { E_AllowedAccountStates } from "@opr-finance/feature-account";

import { AppState, E_Routes } from "../../types/general";
import { messages } from "./messages";

export type T_IconProporties = {
    icon: string;
    text: string;
    route?: string;
    isMoreIcon?: boolean;
};

type MobileNavItemsProps = {
    isMorePageVisible: boolean;
    setIsMorePageVisible: Dispatch<SetStateAction<boolean>>;
};

export const MobileNavItems: FunctionComponent<MobileNavItemsProps> = (
    props: MobileNavItemsProps
) => {
    const { formatMessage: fm } = useIntl();
    const accountState = useSelector((state: AppState) => state.account.accountState);

    const location = useLocation();
    const history = useHistory();

    const isPathMatched = (path: string) => location.pathname === path;

    const iconProporties = [
        {
            icon: "home-lg",
            text: fm(messages.frontPage),
            route: E_Routes.FRONT,
        },
        {
            icon: "file-alt",
            text: fm(messages.mobileLoanInfo),
            route: E_Routes.LOAN,
        },
        {
            icon: "file-check",
            text: fm(messages.mobileTopUp),
            route: E_Routes.TOPUP,
        },
        {
            icon: "ellipsis-h",
            text: fm(messages.mobileMore),
            isMoreIcon: true,
        },
    ];
    const filteredMobileNavItems = () => {
        if (accountState === E_AllowedAccountStates.COLLECTION) {
            let filterdItems = iconProporties.filter(
                (item) =>
                    item.route !== E_Routes.LOAN &&
                    item.route !== E_Routes.TOPUP &&
                    item.icon !== "ellipsis-h"
            );

            filterdItems = [
                ...filterdItems,
                { icon: "user", text: fm(messages.userInfo), route: E_Routes.USER },
                {
                    icon: "comment-alt-dots",
                    text: fm(messages.contactInfo),
                    route: E_Routes.CONTACT,
                },
            ];
            return filterdItems;
        } else {
            return iconProporties;
        }
    };

    return (
        <StyledGrid styleConfig={{ root: MobileNavStyles.mobileNavItemsWrapper() }}>
            {filteredMobileNavItems().map((item, index) => {
                return (
                    <Scroll to="app-base-container" key={`scroll-item-${index}`}>
                        <IconContainer
                            key={`item-${index}`}
                            icon={["fas", `${item.icon}`]}
                            size={"lg"}
                            text={item.text}
                            isActive={() => {
                                if (item.isMoreIcon) {
                                    return props.isMorePageVisible;
                                } else {
                                    return isPathMatched(`${item.route}`);
                                }
                            }}
                            onClick={() => {
                                if (item.isMoreIcon) {
                                    props.setIsMorePageVisible(!props.isMorePageVisible);
                                } else {
                                    history.push(`${item.route}`);
                                    props.setIsMorePageVisible(false);
                                }
                            }}
                        />
                    </Scroll>
                );
            })}
        </StyledGrid>
    );
};
