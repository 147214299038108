import * as VP from "@opr-finance/api-definitions";
import { T_DatepickerConfig } from "@opr-finance/component-datepicker/src/types";
import { DefaultInitializerType } from "@opr-finance/utils";
import { SystemStyleObject } from "@styled-system/css";

export type T_ReportingLoanInfo = {
    globalLoanId?: string;
    loanAccountNumber: VP.components["parameters"]["accountId"];
    companyName: string;
    organizationNumber: string;
    email: string;
    city: string;
    zipcode: string;
    streetAddress: string;
    loanId?: string;
    status: string;
    loanCreateDate?: string;
    applicationNumber?: string;
};

type T_ReportingPeriod = {
    reportingYear?: string;
    reportingStartDate?: string;
    reportingEndDate: string;
    reportGenerationDate?: string;
    reportDate?: string;
};

type T_NotUsed = {
    // data is coming from api-reporting, but not used in reports
    unpaidAmortizationSum?: string;
    totalUnpaidSumStart?: string;
    unpaidAmortizationSumStart?: string;
    payStatementLatePaymentInterestAmount?: string;
    payStatementTrancheStartupFeeAmount?: string;
    payStatementInterestAmount?: string;
    payStatementAdjustmentInterestAmount?: string;
    statementAdjustmentInterestAmount?: string;
    loanAdjustmentInterestAmount?: string;
    statementInterestAmount?: string;
    statementLatePaymentInterestAmount?: string;
    statementTrancheStartupFeeAmount?: string;
};

type T_ReportingAccountData = {
    remainingPrincipalSumEnd?: string;
    remainingPrincipalEnd?: string;
    remainingPrincipal?: string;
    remainingPrincipalSumStart: string;
    interestTotal: string;
    startupFeeAmountTotal: string;
    unpaidInterest: string;
    unpaidTrancheStartUpFee: string;
    unpaidLatePaymentInterest: string;
    totalUnpaidAmount: string;
    totalPaidAmortization: string;
    totalPaidInterest: string;
    totalPaidLatePaymentInterest: string;
    totalPaidStartupFee: string;
    totalPaidLatePaymentFee: string;
};
type T_ReportingAccountDataNl = Pick<
    T_ReportingAccountData,
    | "remainingPrincipalSumEnd"
    | "remainingPrincipalSumStart"
    | "interestTotal"
    | "startupFeeAmountTotal"
>;

type T_ReportingAccountDataFi = Pick<
    T_ReportingAccountData,
    | "remainingPrincipal"
    | "unpaidInterest"
    | "unpaidTrancheStartUpFee"
    | "unpaidLatePaymentInterest"
    | "totalUnpaidAmount"
>;

type T_ReportingAccountDataSe = Pick<
    T_ReportingAccountData,
    | "remainingPrincipalEnd"
    | "totalPaidAmortization"
    | "totalPaidInterest"
    | "totalPaidLatePaymentInterest"
    | "totalPaidStartupFee"
    | "totalPaidLatePaymentFee"
>;

export type T_YearlyOverviewDataNl = T_ReportingLoanInfo &
    T_ReportingPeriod &
    T_ReportingAccountDataNl;

export type T_YearlyOverviewDataSe = T_ReportingLoanInfo &
    T_ReportingPeriod &
    T_ReportingAccountDataSe;

export type T_YearlyOverviewDataFi = T_ReportingLoanInfo &
    T_ReportingPeriod &
    T_ReportingAccountDataFi;

export type T_YearlyOverviewData =
    | T_YearlyOverviewDataNl
    | T_YearlyOverviewDataFi
    | T_YearlyOverviewDataSe;

export enum E_ReportingActionConstants {
    REPORTING_INITIALIZER = "REPORTING/INITIALIZER",
    REPORTING_TRIGGER = "REPORTING/TRIGGER",
    REPORTING_ERROR = "REPORTING/FETCH_REPORT_ERROR",
    REPORTING_SUCCESS = "REPORTING/FETCH_REPORT_SUCCESS",
}

export type T_ReportingPayload = {
    reportingEndDate?: string;
};

export type T_ReportingInitializerType = DefaultInitializerType & {
    cid: string;
    accountNumber: string | undefined;
    country: string;
};

export type T_ReportingSuccessAction = {
    yearlyOverview: T_YearlyOverviewData | T_ReportingLoanInfo;
    status: "SUCCESS" | "FAIL" | "";
};

export type T_ReportingReducerState = T_ReportingSuccessAction & {
    config: T_ReportingInitializerType;
};

export type T_FeatureReportingState = {
    reporting: T_ReportingReducerState;
};

export type T_ReportingComponentProps = {
    cid: string;
    accountCreatedDate: string;
    locale: "fi" | "sv" | "nl";
    dateFormat: T_DatepickerConfig["dateFormat"];
    translations: {
        blockHeading: string;
        reportingInstructions: string;
        noReportsMessage: string;
        dateInputPlaceholder?: string;
        buttonText?: string;
        loadingText: string;
        errorText: string;
    };
    reportingYears?: number[];
    pdfGeneratorUrl: string;
    styles: {
        rootContainer: SystemStyleObject;
        blockHeadingStyles: SystemStyleObject;
        reportingInstructionsText: SystemStyleObject;
        reportsContainer: SystemStyleObject;
        button: SystemStyleObject;
        buttonText: SystemStyleObject;
        reportingDatepicker: SystemStyleObject;
        errorText: SystemStyleObject;
    };
};

export enum E_FlexOnlineCid {
    fi = "finland-flex-online",
    se = "sweden-flex-online",
    nl = "netherlands-flex-online",
}
