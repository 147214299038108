import { CurrencyLocale, CurrencyFormat } from "./types";

export type CurrencyProps = {
    value: string | number | undefined;
    locale: CurrencyLocale;
    currency: CurrencyFormat;
    minimumFractionDigits?: number;
    maximumFractionDigits?: number;
    style?: string;
};

export const currency = ({
    value,
    locale,
    currency,
    minimumFractionDigits = 2,
    maximumFractionDigits = 2,
    style = "currency",
}: CurrencyProps): string => {
    if (typeof value === "undefined") return "Error";
    const parsedValue: number = typeof value === "number" ? value : parseFloat(value);
    return parsedValue.toLocaleString(locale, {
        style,
        currency,
        currencyDisplay: "symbol",
        minimumFractionDigits,
        maximumFractionDigits,
    });
};
