import { defineMessages } from "react-intl";

export const messages = defineMessages({
    pageTitle: {
        id: "startPage.pageTitle",
    },
    loginTitle: {
        id: "startPage.loginHeading",
    },
    applyTitle: {
        id: "startPage.applyHeading",
    },
    loginPathText: {
        id: "startPage.LoginToFlexOnlineText",
    },
    loginButtonText: {
        id: "startPage.loginText",
    },
    applyButtonText: {
        id: "startPage.applyText",
    },
});
